import React from 'react';
import { Newsletter, Jumbotron } from '../components';
import { Rental } from '../components/RentalPolicy';
import Layout from '../containers/Layout'

export default function RentalPolicy() {
  return(
      <Layout title={"Rental Policy"}>
          <Jumbotron btn='Rental Policy' title='Rental Policy' subTitle=''/>
          <Rental/>
          <Newsletter/>
      </Layout>
  );
}


