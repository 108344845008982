import React from "react";
import * as Animate from "react-reveal";
import { Link } from "gatsby";

export default function Rental() {
  return (
    <section>
      <div className="w-11/12 mx-auto my-16 max-w-7xl">
        <div className="flex flex-col justify-center items-center ">
          <Animate.Fade bottom>
            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <p className="mb-4">
                All rentals made by Reeddi ("Company") to its rental customers
                (“Renters”/”You”) are subject to the following Rental terms and
                conditions. Renter’s acceptance of these terms and conditions
                shall be made by either (a) Renter providing a rental order
                number to Company or (b) Renter’s acceptance of any system from
                Company, whichever occurs first.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                1. Condition of Our Energy Systems
              </h3>

              <p className="mb-4">
                The Renter acknowledges the receipt of the energy system in good
                working condition and further acknowledges that Renter has
                examined and received the system in good working condition. The
                Renter’s failure to object in writing to the condition of the
                system within 6 hours after receipt thereof shall be deemed
                conclusive that all of the systems were in good working order
                when delivered.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl font-bold text-[#000] mb-4">
                2. Rental Period
              </h3>

              <p className="mb-4">
                2.1 The Rental Period shall be as set out in the Rental Order,
                unless the order is terminated earlier in accordance with these
                terms and conditions.
              </p>

              <p className="mb-4">
                2.2 The Rental Period may be extended by mutual written
                agreement of both parties and the daily rate set out in the
                Order shall apply to any extended Rental Period.
              </p>

              <p>
                2.3 If the Rental Period has not been extended in accordance
                with clause 2.2 and the system is not returned to the Company on
                the return date set out in the Order, the Renter will be charged
                at the daily rate set out in the Order until the system has been
                returned to the Company.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                3. Delivery{" "}
              </h3>
              <p className="mb-4">
                3.1 The Company shall deliver the system to the location set out
                in the Order or such other place as the parties may agree at any
                time after the Company notifies the Renter that the system is
                ready for delivery.
              </p>
              <p>
                3.2 The Company shall deliver the system on the delivery dates
                stated by the Company. It is however expressly agreed that the
                Company shall not be liable to the Renter for any delay in
                delivery of the system.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">4. Title </h3>
              <p className="mb-4">
                4.1 The system shall at all times remain the property of the
                Company, and the Renter shall have no right, title, or interest
                in or to the system (save the right to possession and use of the
                system subject to these Conditions).
              </p>
              <p className="mb-4">
                4.2 The Renter shall give the Company immediate notice in the
                event the system is lost, stolen, or damaged as a result of the
                Renter’s possession or use of the system or if there is a claim
                or, for any reason, a threat of seizure of the system.
              </p>
              <p className="mb-4">
                4.3 The risk of loss, theft, damage, or destruction of the
                system shall pass to the Renter upon delivery of the system to
                the Renter, or where applicable, the Renter’s appointed agent.
              </p>
              <p className="mb-4">
                4.4 The system shall remain at the sole risk of the Renter
                during the Rental Period and any further term during which the
                system is in the possession, custody, or control of the Renter
                (“Risk Period”) until such time as the system is returned to the
                Company.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                5. Obligation of the Company{" "}
              </h3>
              <p>
                The Company shall keep the system in a proper and operational
                state ready for collection by the Renter.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                6. Use of the System
              </h3>
              <p>
                The system is rented out for use to power electrical appliances.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                7. Obligations of the Renter{" "}
              </h3>
              <p className="mb-4">
                The Renter shall during the Rental Period:{" "}
              </p>
              <p className="mb-4">
                (a) keep and maintain the system in good condition and
                appearance in order to keep it in as good an operating condition
                as it was on the commencement date of the Rental Period (fair
                wear and tear only accepted);
              </p>
              <p className="mb-4">
                (b) use all system only for the purposes for which it was
                designed and operate in a careful, prudent manner and in
                accordance with the instructions for use;
              </p>
              <p className="mb-4">
                (c) comply with all laws, regulations, and rules relating to the
                possession, use, and storage of the system;
              </p>
              <p className="mb-4">
                (d) maintain effective control of the system and keep the system
                in a secure and suitable environment when not in use;
              </p>
              <p className="mb-4">
                (e) ensure that the system is operated only by suitably
                competent persons, duly instructed on its safe operation in
                accordance with the Company’s operating and safety instructions;
              </p>
              <p className="mb-4">
                (f) ensure that no unauthorized transfers or diversions of the
                system occurs;
              </p>
              <p className="mb-4">
                (g) not remove, alter, disfigure, or cover up any numbering,
                lettering, or insignia displayed on the system or any warnings
                or documentation thereon;
              </p>
              <p className="mb-4">
                (h) not copy or reproduce in any way or manner the system or any
                part or component of the system; (i) not perform, or allow any
                person to perform, any work in or upon or make modifications,
                changes, alterations or repairs to the system;
              </p>
              <p className="mb-4">
                (j) keep the system free from any liens, claims, charges, and
                encumbrances during the Rental Period;
              </p>
              <p className="mb-4">
                (k) keep the Company fully informed of all material matters
                relating to the system;
              </p>
              <p className="mb-4">
                (l) deliver the system at the end of the Rental Period at such
                address as the Company requires. If necessary, allow the Company
                or its representatives access to the premises where the system
                is located to remove the system.
              </p>
              <p className="mb-4">
                (m) not do or permit to be done anything which could invalidate
                the insurance referred to in clause 10
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                8. Liability and Indemnity{" "}
              </h3>
              <p>
                The Renter shall be liable for any damage arising from the
                system rental use, misuse, or negligence of the Renter,
                including any accidents to persons or property arising thereof.
                Renter covenants and agrees to indemnify and hold harmless the
                Company free from any and all claims, actions, suits, damages,
                and liabilities, including attorney’s fees, arising out of,
                connected with, or resulting from the possession or use of the
                rented system.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                9. Consequential Damages{" "}
              </h3>
              <p>
                The Company shall not be liable for any consequential,
                incidental, or contingent damages whatsoever.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                10. Insurance{" "}
              </h3>
              <p>
                During the Rental Period and the Risk Period, the Customer
                shall, at its own expense, provide and maintain the insurance of
                the system against risks of physical loss or damage by fire,
                theft, or accident, in an amount equal to the full replacement
                value.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                11. Payment Terms{" "}
              </h3>
              <p>
                Once a Rental Order is confirmed, the Company shall
                automatically deduct the Rental Fee for the Rental Period from
                the Renter’s account. Where the Rental Period is extended, the
                Company shall invoice the Renter for the Renter Fee and
                automatically deduct payment for the extended Rental Period in
                accordance with the Order. If the system is returned later than
                the return date specified in the Order, the Renter will be
                charged at the daily rate set out in the Order until the system
                has been returned to the Company. If the Renter fails to make
                any payment due to the Company by the due date for payment,
                then, without limiting the Company’s remedies under clause 12,
                the Renter shall pay interest on the overdue amount at the rate
                of 2.5% per day. Such interest shall accrue on a daily basis
                from the due date until the actual payment of the overdue
                amount, whether before or after negotiations or orders from a
                competent authority. The Renter shall pay the interest together
                with the overdue amount.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                12. Additional Charges{" "}
              </h3>
              <p className="mb-4">
                12.1 In addition to the Rental Payment specified in the Order,
                the Company has, in the relevant circumstances, the right to
                apply additional charges:
              </p>
              <p className="mb-4">
                (a) for loss of or damage to the system as a result of any
                failure by the Renter to comply with these Conditions;
              </p>
              <p className="mb-4">
                (b) if the system is damaged and it can be repaired, for loss of
                rental income during the period the system is being repaired,
                provided such additional charges shall cease when the system is
                returned to full working condition;
              </p>
              <p className="mb-4">
                (c) if the system is damaged and cannot be repaired (i.e. it is
                written off) or the system is lost or stolen, for loss of rental
                income during the period from the date the system is damaged,
                lost, or stolen to the date the Company receives an amount equal
                to the full replacement value of the system; and
              </p>
              <p className="mb-4">
                (d) for delivery and/or collection of the system.
              </p>
              <p>
                12.2 The Company shall invoice the Renter for any Additional
                Charges in accordance with this clause and VAT (and any other
                applicable taxes) shall be applied to any Additional Charges.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                13. Rent-to-Own Plan{" "}
              </h3>
              <p>
                The Renter may decide to rent the system under the Company’s
                rent-to-own plan. Rentals under this plan are subject to this
                policy and the terms and conditions in the Rent-to-Own Agreement
                provided. In the event of conflicting terms, the Rent-to-Own
                Agreement shall prevail over this Policy.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                14. Communication{" "}
              </h3>
              <p className="mb-4">
                Every communication between the Company and the Renter shall be
                sent to the Renter’s registered email address. Notices and
                information to the Company must be sent to:{" "}
                <a
                  href="mailto:hello@reeddi.com"
                  className="underline hover:text-reedditeal"
                >
                  hello@reeddi.com.
                </a>
              </p>

              <p>
                If there’s any change with the Renter’s email address, the
                Company must be notified within 24 hours.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                15. Complaints{" "}
              </h3>
              <p>
                If you are dissatisfied with your rental, please send your
                complaints to:{" "}
                <a
                  href="mailto:support@reeddi.com"
                  className="underline hover:text-reedditeal"
                >
                  support@reeddi.com.{" "}
                </a>
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                16. Data Protection{" "}
              </h3>
              <p>
                Your privacy is important to us. By using this site, you accept
                the terms of our privacy notice described in our{" "}
                <Link to="/privacy-notice">
                  <span className="underline hover:text-reedditeal">
                    privacy policy.
                  </span>
                </Link>
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                17. Reviews{" "}
              </h3>
              <p>
                Reviews from Renters may be uploaded onto our website and social
                media platforms etc., for the purpose of informing potential
                customers of your opinion of the service and quality of the
                system. By submitting a review, you consent to the use of your
                review. We reserve the right to adjust, refuse or remove reviews
                at our sole discretion.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                18. Waivers{" "}
              </h3>
              <p>
                No failure or delay by a party to exercise any right or remedy
                provided under this Policy or by law shall constitute a waiver
                of that or any other right or remedy, nor shall it prevent or
                restrict the further exercise of that or any other right or
                remedy. No single or partial exercise of such right or remedy
                shall prevent or restrict the further exercise of that or any
                other right or remedy.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                19. Dispute Resolution{" "}
              </h3>
              <p>
                Any dispute arising in respect of this Policy, interpretation of
                rights, duties, and obligations shall be resolved between the
                Parties amicably within 14 days, failing which the dispute shall
                be referred to the court of law in Lagos State. The Party in
                default shall bear the cost of the proceedings.
              </p>
            </div>

            <div className="w-full text-sm text-cstm-grey flex flex-col mb-8 md:text-base md:w-[80%] lg:w-[60%]">
              <h3 className="text-2xl mb-4 font-bold text-[#000]">
                20. Governing Law{" "}
              </h3>
              <p>
                This Rental Policy, and any dispute or claim arising out of or
                in connection with it or its subject matter or formation
                (including non-contractual disputes or claims), shall be
                governed by, and construed in accordance with the Laws of the
                Federal Republic of Nigeria. If any part of this policy is
                declared illegal or unenforceable, the provision will become
                void, leaving the remainder of this Agreement in full force and
                effect.
              </p>
            </div>
          </Animate.Fade>
        </div>
      </div>
    </section>
  );
}
